const $ = jQuery;

export const runAccordion = (attributes, clientId, isBackend = false, custom = {}) => {
    const { layout } = attributes;

    'vertical' === layout && $.Zebra_Accordion(`#babAccordion-${clientId} .babAccordion`, {
        animate_opacity: true,
        collapsible: false,
        expanded_class: 'accordionExpanded',
        hide_speed: 400,
        scroll_speed: 600,
        show: isBackend ? custom.activeIndex : 0,
        toggle_on_mouseover: false
    });

    'horizontal' === layout && $(function () {
        $(`#babAccordion-${clientId} .babAccordion dt`).first().addClass('accordionExpanded');

        // Show First Info
        $(`#babAccordion-${clientId} .babAccordion dd`).first().show().animate({ flexGrow: 1 });

        // Show Info On Click
        $(`#babAccordion-${clientId} .babAccordion dt`).click(function () {
            $(this).addClass('accordionExpanded').siblings(`#babAccordion-${clientId} .babAccordion dt`).removeClass('accordionExpanded');

            $(this).next().show().animate({ flexGrow: 1 }).siblings(`#babAccordion-${clientId} .babAccordion dd`).animate({ flexGrow: 0 }, function () {
                $(this).hide();
            });
        });
    });
}