import { useEffect } from 'react';
import { render } from 'react-dom';

import './style.scss';
import Style from './Style';
import Accordions from './Accordions';
import { runAccordion } from './utils/functions';

// Accordion
document.addEventListener('DOMContentLoaded', () => {
	const allAccordion = document.querySelectorAll('.wp-block-bab-accordion');
	allAccordion.forEach(accordion => {
		const attributes = JSON.parse(accordion.dataset.attributes);
		const { cId } = attributes;

		render(<>
			<Style attributes={attributes} clientId={cId} />

			<RenderAccordion attributes={attributes} clientId={cId} />
		</>, accordion);

		accordion?.removeAttribute('data-attributes');
	});
});

const RenderAccordion = ({ attributes }) => {
	const { cId, accordions, layout } = attributes;

	useEffect(() => {
		runAccordion(attributes, cId);
	}, []);

	return <dl className={`babAccordion ${layout || 'horizontal'}`}>
		<Accordions accordions={accordions} />
	</dl>
}