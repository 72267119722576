import { getColorsCSS, getTypoCSS } from '../../Components/Helper/getCSS';


const Style = ({ attributes, clientId }) => {
	const { textAlign, titleTypo, titleColors, titleActiveColors, contentTypo, contentColors } = attributes;

	const accordionSl = `#babAccordion-${clientId} .babAccordion`;

	return <style dangerouslySetInnerHTML={{
		__html: `
		${getTypoCSS('', titleTypo)?.googleFontLink}
		${getTypoCSS('', contentTypo)?.googleFontLink}
		${getTypoCSS(`${accordionSl} dt`, titleTypo)?.styles}
		${getTypoCSS(`${accordionSl} dd`, contentTypo)?.styles}

		${accordionSl} dt,
		${accordionSl} dd{
			text-align: ${textAlign};
		}
		${accordionSl} dt{
			${getColorsCSS(titleColors)}
		}
		${accordionSl} dt.accordionExpanded{
			${getColorsCSS(titleActiveColors)}
		}
		${accordionSl} dd{
			${getColorsCSS(contentColors)}
		}
		`.replace(/\s+/g, ' ')
	}} />
}
export default Style;